import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import style from "./VideoForm.module.css"; // Import the CSS file
import { IMAGES } from "../../assets/images/images";
import { useParams } from "react-router-dom";
import {
  MARK_VIDEO_AS_WATCHED,
  VIDEO_FORM_SUBMIT,
  SAVE_VIDEO_PROGRESS,
  GET_VIDEO_PROGRESS,
  GET_VIDEO_DETAILS,
} from "../../services/URL";

const VideoForm = () => {
  const [User, setUser] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasCompleted, setHasCompleted] = useState(false);
  const [initialTime, setInitialTime] = useState(0);
  const [isThankyouMsg, setIsThankyouMsg] = useState(false);
  const videoRef = useRef(null);
  const [videoDetails, setVideoDetails] = useState({
    jobTitle: "",
    videoLink: "",
    notes: "",
  });
  const { companyId, siteId } = useParams();
  let videoLink;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const getFormDetail = async () => {
    try {
      const response = await axios.post(GET_VIDEO_DETAILS, {
        companyId,
        siteId,
      });
      setVideoDetails(response.data.videoDetails);
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  };

  // function convertDriveLink(url) {
  //   // Check if the input URL is a valid string
  //   if (!url || typeof url !== 'string') {
  //     console.error('Invalid URL input');
  //     return null; // Return null if the URL is not valid
  //   }

  //   // Regular expression to extract the file ID
  //   const regex = /(?:\/d\/|id=)([\w-]+)/;
  //   const match = url.match(regex);
  //   const fileId = match ? match[1] : null;

  //   if (!fileId) {
  //     console.error('Could not extract file ID from URL');
  //     return null; // Return null if no ID is found
  //   }

  //   // Construct the new format you want, e.g., a preview link
  //   return `https://drive.google.com/file/d/${fileId}/preview`;
  // }

  // Call the function when needed
  useEffect(() => {
    getFormDetail();
  }, [companyId, siteId]); // Ensure dependencies are included if they can change

  // useEffect(() => {
  //   videoLink = convertDriveLink(videoLink);
  //   console.log("videoLink", videoLink);

  // }, [videoDetails]);

  useEffect(() => {
    if (userEmail && userEmail !== "") {
      console.log("email", userEmail);

      axios
        .post(GET_VIDEO_PROGRESS, { email: userEmail })
        .then((response) => {
          if (response.data.watchedVideo) {
            console.log(response.data.watchedVideo);
            setVideoWatched(true);
          }
          setInitialTime(response.data.lastWatchedTime || 0);
        })
        .catch((error) => {
          console.error("Error fetching video progress:", error);
        });
    }
  }, [userEmail]);

  useEffect(() => {
    // const formSubmitted = localStorage.getItem("isFormSubmitted");
    const UserEmail = localStorage.getItem("userEmail");
    // console.log("formSubmitted", formSubmitted);

    if (UserEmail) {
      // console.log("formSubmitted", formSubmitted);
      setUserEmail(UserEmail);
      setIsFormSubmitted(true);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(VIDEO_FORM_SUBMIT, data);
      if (response.data.data) {
        setUser(response.data.data);
        setHasCompleted(response.data.data.watchedVideo);
        setUserEmail(response.data.data.email);
      }
      setIsFormSubmitted(true);
      // localStorage.setItem("isFormSubmitted", "true");
      localStorage.setItem("userEmail", response.data.data.email);

      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleVideoCompletion = async () => {
    try {
      const response = await axios.post(MARK_VIDEO_AS_WATCHED, {
        email: userEmail,
      });
      if (response.data.data) {
        setVideoWatched(true);
        setHasCompleted(true);
      }
    } catch (error) {
      console.error("Error updating video status:", error);
    }
  };

  const saveVideoProgress = async (currentTime) => {
    try {
      await axios.post(SAVE_VIDEO_PROGRESS, {
        email: userEmail,
        currentTime: currentTime,
      });
    } catch (error) {
      console.error("Error saving video progress:", error);
    }
  };
  const handleLogOut = () => {
    setIsFormSubmitted(false);
    setIsThankyouMsg(false);
    setUserEmail(""); // Clear email state
    setUser(""); // Clear user state
    setVideoWatched(false); // Reset video watched status
    setHasCompleted(false); // Reset completion status
    setInitialTime(0); // Reset initial video time
    setErrorMessage(""); // Clear any error messages
    reset(); // Reset the form
    localStorage.clear(); // Clear all local storage items
  };

  const handleVideoTimeUpdate = () => {
    const video = videoRef.current;
    if (video && userEmail) {
      // Track time locally but don't save every few seconds
      if (video.currentTime === video.duration) {
        handleVideoCompletion();
      }
    }
  };
  // Handle resubmission error
  const handleResubmitError = () => {
    setErrorMessage("Please watch the full video before proceeding.");
  };

  const handleBeforeUnload = () => {
    const video = videoRef.current;
    if (video && userEmail) {
      saveVideoProgress(video.currentTime);
    }
    // localStorage.setItem('isTabClosing', 'true');
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.currentTime = initialTime;
    }

    // Check if the tab is being reopened

    // Add event listener for beforeunload (when the user closes the tab or window)
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup the event listener and save progress when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (video && userEmail) {
        saveVideoProgress(video.currentTime);
      }
    };
  }, [initialTime, userEmail]);

  const thankyouMsg = () => {
    return (
      <div className={style.completionScreen}>
        <div className={style.logoContainer}>
          <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
        </div>
        <p className={style.completionMessage}>
          You have Completed Watching Your Jobsite Orientation Video
        </p>
        <button
          // disabled={!videoWatched}
          onClick={handleLogOut}
          className={`${style.proceedButton}`}
        >
          Log Out
        </button>
      </div>
    );
  };

  return (
    <div className={style.videoFormContainer}>
      {!isFormSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          {/* Logo Section */}
          <h2 className={style.jobTitle}>{videoDetails.jobTitle}</h2>
          <div className={style.logoContainer}>
            <img className="mb-1" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <h2 className={style.notes}>{videoDetails.notes}</h2>

          <div className={style.formGroup}>
            <input
              type="text"
              placeholder="Name"
              autoComplete="off"
              {...register("name", { required: "Name is required" })}
              className={style.input}
            />
            {errors.name && (
              <p className={style.errorMessage}>{errors.name.message}</p>
            )}
          </div>

          <div className={style.formGroup}>
            <input
              type="email"
              placeholder="Email"
              autoComplete="off"
              {...register("email", {
                required: "Email is required",

                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              className={style.input}
            />
            {errors.email && (
              <p className={style.errorMessage}>{errors.email.message}</p>
            )}
          </div>

          <div className={style.formGroup}>
            <input
              type="tel"
              placeholder="Phone"
              autoComplete="off"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                  message: "Invalid phone number",
                },
              })}
              className={style.input}
            />
            {errors.phone && (
              <p className={style.errorMessage}>{errors.phone.message}</p>
            )}
          </div>

          <button type="submit" className={style.submitButton}>
            Submit
          </button>
        </form>
      ) : isThankyouMsg ? (
        thankyouMsg()
      ) : (
        <div className={style.videoContainer}>
          <div className={style.logoContainer}>
            <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <video
            ref={videoRef}
            width="600"
            controls
            // onSeeking={handleSeeking}
            onTimeUpdate={handleVideoTimeUpdate}
            className={`${videoWatched ? style.watchedVideo : style.video}`}
          >
            <source src={videoDetails.videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <iframe
            src={videoLink}
            width="600"
            height="400"
            allow="autoplay"
            title="Google Drive Video Player"
          ></iframe> */}
          {!videoWatched && (
            <p className={style.errorMessage}>{errorMessage}</p>
          )}
          <button
            disabled={!videoWatched}
            onClick={
              videoWatched ? () => setIsThankyouMsg(true) : handleResubmitError
            }
            className={`${style.proceedButton} ${
              videoWatched ? "" : style.disabled
            }`}
          >
            Proceed
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoForm;
