import React from "react";
import Card from "react-bootstrap/Card";
import styles from "./EmployeeDetails.module.css";
import { Spinner } from "react-bootstrap";

const UserInfo = ({
  title,
  description,
  subTitle,
  externalNumber,
  userImg,
  loading,
}) => {
  return (
    <>
      <Card.Title>{title}</Card.Title>
      <div className={styles.userDetailWrapper}>
        {loading ? (
          <Spinner className={`text-success ${styles.spinLoader}`} />
        ) : (
          <img src={userImg} alt="userimg" className={styles.userImg} />
        )}
        <div className="">
          <Card.Text className="text-black mb-1">{description}</Card.Text>
          <Card.Subtitle>{subTitle}</Card.Subtitle>
          <Card.Text className="text-black fs-7">{externalNumber}</Card.Text>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
