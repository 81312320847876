import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader/Loader";
import SearchBar from "../../Components/Common/Searchbar/SearchBar";
import Spinner from "../../Components/Common/Spinner/Spinner";
import classes from "../../Components/UserPage/Index.module.css";
import EmployeeDataTable from "../../Components/employeetable/EmployeeDataTable";
import { IMAGES } from "../../assets/images/images";
import {
  ASSIGNED_CREDENTIALS,
  EVENTS_HISTORY,
  FETCH_USER_DETAILS,
  GET_ALL_EMPLOYEES,
} from "../../services/URL";
import { api } from "../../services/auth.serice";

const Employees = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [eventSearchText, setEventSearchText] = useState("");
  const [sort, setSort] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowEventHistory, setIsShowEventHistory] = useState(false);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState("");
  const [eventsUser, setEventsUser] = useState({});
  const [spinLoader, setSpinLoader] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const { format } = require("date-fns");
  const companyId = localStorage.getItem("companyId");
  const siteId = localStorage.getItem("siteId");

  useEffect(() => {
    if (searchText.length > 0) {
      handleSearch().then();
    } else {
      getUsers();
    }
  }, [searchText]);

  const handleSearch = async () => {
    const userSiteId = localStorage.getItem("siteId");
    setIsLoading(true);
    try {
      const res = await api.searchAllEmployees({
        name: searchText,
        siteId: userSiteId,
        companyId: companyId,
      });
      // console.log("response search", res.data);
      if (res.status === 200) {
        setUsers([...(res.data || [])]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("handleSearch", error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getUsers();
  // }, []);
  const getUsers = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        GET_ALL_EMPLOYEES,
        { siteId, companyId },
        { headers }
      );

      if (res.status === 200) {
        setUsers(res?.data?.data);
        setIsLoading(false);
      }
    } catch (res) {
      console.error("API Errors:", res.error[0]);
      setIsLoading(false);
    }
  };

  const handleShowEventHistory = async (item) => {
    setEventsUser(item);
    setUserDetails({});
    setIsShowEventHistory(true);
    setSpinLoader(true);

    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const [eventsResponse, userDetailsResponse] = await Promise.all([
        axios.post(
          EVENTS_HISTORY,
          { userId: item.id.toString(), companyId },
          { headers }
        ),
        axios.post(
          FETCH_USER_DETAILS,
          { userId: item.id.toString(), companyId },
          { headers }
        ),
      ]);

      if (eventsResponse.status === 200) {
        setEvents(eventsResponse.data.data);
        setUserId(item.id.toString());
      }

      if (userDetailsResponse.status === 200) {
        setUserDetails(userDetailsResponse.data.data);
      }
    } catch (error) {
      console.error("API Errors:", error);
    } finally {
      setSpinLoader(false);
    }
  };

  useEffect(() => {
    if (sort) {
      const sortArr = users?.sort((a, b) => {
        const { firstName: fa } = a;
        const { firstName: fb } = b;
        if (fa?.toLowerCase() < fb?.toLowerCase()) {
          return -1;
        }
        if (fa?.toLowerCase() > fb?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([...sortArr]);
    }
  }, [sort]);

  const handleNameSort = (column, sortDirection) => {
    const sortedUsers = [...users]; // Make a copy of the original 'users' array

    sortedUsers.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
      const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();

      if (nameA < nameB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      // If the names are equal, sort by email
      // const emailA = a.email.toUpperCase();
      // const emailB = b.email.toUpperCase();

      // if (emailA < emailB) {
      //   return sortDirection === "asc" ? -1 : 1;
      // }
      // if (emailA > emailB) {
      //   return sortDirection === "asc" ? 1 : -1;
      // }

      return 0;
    });

    // Update the state with the sorted array
    setUsers(sortedUsers);
  };

  const search = useCallback((e) => {
    setSearchText(e);
  }, []);

  const eventsChecked = (date) => {
    const apiResponseDate = date;
    const dateObj = new Date(apiResponseDate);
    return format(dateObj, "MMM dd, hh:mm aaa").toUpperCase();
  };

  useEffect(() => {
    filterEvents();
  }, [eventSearchText]);

  const searchEvents = (query) => {
    setEventSearchText(query);
  };

  const filterEvents = () => {
    if (eventSearchText.length > 0) {
      if (eventSearchText && eventSearchText.trim() === "") {
        setFilteredEvents([...events]);
      } else {
        const filtered = events.filter((event) => {
          return (
            (eventSearchText.toLowerCase() === "valid" &&
              event?.accessGrant === "Valid") ||
            (eventSearchText.toLowerCase() === "invalid" &&
              event?.accessGrant === "Invalid") ||
            (eventSearchText.toLowerCase() === "check in" &&
              event?.checkedType === "checkin") ||
            (eventSearchText.toLowerCase() === "check out" &&
              event?.checkedType === "checkout") ||
            event?.userName
              .toLowerCase()
              .includes(eventSearchText.toLowerCase()) ||
            event?.accessPointName
              .toLowerCase()
              .includes(eventSearchText.toLowerCase()) ||
            event?.siteName
              .toLowerCase()
              .includes(eventSearchText.toLowerCase())
          );
        });
        setFilteredEvents(filtered);
      }
    }
  };

  let resutlList = eventSearchText.length > 0 ? filteredEvents : events;

  useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const userDetailsResponse = axios.post(
        FETCH_USER_DETAILS,
        { userId, companyId },
        { headers }
      );
      if (userDetailsResponse.status === 200) {
        setUserDetails(userDetailsResponse.data.data);
      }
    } catch (error) {}
  }, [userId]);

  
  const handleGetName = (item) => {
    // handleRowClicked(item);
    // navigate(`/employee/${item.id}`, {
    //   state: { employeeData: item, userDetails: userDetails },
    // });
  };
  const handleRowClicked = async (item) => {
    setUserId(item.id.toString());
  };

  return (
    <div className={classes.userDashboardWrapper}>
      <div className={`${classes.userDashboard} w-100`}>
        <div className={`${classes.leftSide} `}>
          <div>
            <h1>Secure Scan Admin</h1>
          </div>
          <div className="row py-4">
            <div className="col-sm-5 position-relative">
              <SearchBar
                placeholder="Search Employee"
                search={(e) => search(e)}
                handleSort={() => setSort(true)}
              />
            </div>
          </div>
          <EmployeeDataTable
            users={users}
            handleShowEventHistory={(id) => handleShowEventHistory(id)}
            handleNameSort={() => handleNameSort}
            handleGetName={(item) => handleGetName(item)}
            handleRowClicked={(item) => handleRowClicked(item)}
          />
        </div>
        {isShowEventHistory && (
          <Modal
            show={setIsShowEventHistory}
            onHide={() => {
              setIsShowEventHistory(false);
              setEventSearchText("");
            }}
            size="md"
          >
            <Modal.Header className="modal-user" closeButton />
            <Modal.Body className="p-4 client-dtaa-wrapper">
              <h1 className="Evant-heading">Event History</h1>
              <div className="empolyee-history-wrapper">
                <div className="profile-head">
                  <div className="right-side-wrapper">
                    <div className="img-boxx">
                      {spinLoader ? (
                        <div className="text-center spinnerLoaderWrapper">
                          <Spinner />
                        </div>
                      ) : (
                        <img
                          loading="lazy"
                          src={
                            userDetails.userImage?.userImageUri ||
                            userDetails?.userImage?.userImageUri ||
                            IMAGES.avatarImage
                          }
                          alt="Employee-profile"
                        />
                      )}
                    </div>
                  </div>
                  <div className="left-side-wrapper ps-3">
                    <div className="left-content-box">
                      <h6>
                        {eventsUser.firstName} {eventsUser.lastName}
                      </h6>
                      <p>
                        <span>Id: </span>
                        <span>{eventsUser.id}</span>
                      </p>
                      <h6 className="mb-0">Events</h6>
                      <span className="valid-acces-evetns">Valid Access</span>
                    </div>
                  </div>
                </div>
                <div className="search-bar pt-4 pb-4">
                  <SearchBar
                    placeholder="Search Events"
                    search={(e) => searchEvents(e)}
                  />
                </div>
                <div className="py-3">
                  {spinLoader ? (
                    <div className="text-center w-100">
                      <Spinner />
                    </div>
                  ) : resutlList.length > 0 ? (
                    <>
                      {resutlList.map((event) => (
                        <div className="employee-events-details">
                          <div className="Left-details">
                            <p>
                              <span className="user-left-heading">User:</span>
                              <span className="user-name">
                                {event.userName}
                              </span>
                            </p>
                            <p>
                              <span className="user-left-heading">Site:</span>
                              <span className="user-name">
                                {event.siteName}
                              </span>
                            </p>
                            <p>
                              <span className="user-left-heading">Device:</span>
                              <span className="user-name">
                                {event.accessPointName}
                              </span>
                            </p>
                            <p>
                              <span className="user-left-heading">Status:</span>
                              <span
                                className={`user-name ${
                                  event.checkedType === "checkout"
                                    ? "custom-text-danger"
                                    : "custom-text-success"
                                }`}
                              >
                                {event.checkedType === "checkout"
                                  ? "Check Out"
                                  : "Check In"}
                              </span>
                            </p>
                          </div>

                          <div className="right-details">
                            <div className="right-side-status">
                              <span
                                className={`cursor-pointer valid-acces ${
                                  event.accessGrant === "Invalid"
                                    ? "deactivatedIconWrapper"
                                    : "activatedIconWrapper"
                                }`}
                              >
                                {event.accessGrant === "Invalid"
                                  ? "Invalid Access "
                                  : "Valid Access"}
                              </span>
                              <span className="date-employee text-center d-block">
                                {eventsChecked(event.checkedAt)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="text-black text-center w-100 fs-6">
                      No event found!
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default Employees;
