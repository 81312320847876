import React from 'react';

// Badge component
const Badge = ({ text, status,handleClick }) => {
  const getBackgroundColor = (status) => {
    switch (status) {
      case 'success':
        return 'rgba(144, 238, 144, 0.14)'; // Green
      case 'rejected':
        return 'rgba(220,53,69, 0.14)'; // Red
      case 'pending':
        return 'rgba(255,193,7, 0.14)'; // Yellow
      default:
        return '#ffc107'; // Default to yellow
    }
  };



  const backgroundColor = getBackgroundColor(status);
  const getTextColor = () => {
    switch (status) {
      case 'success':
        return '#28a745';
      case 'rejected':
        return '#dc3545';
      case 'pending':
        return '#ffc107';
      default:
        return '#ffc107'; // Default to black
    }
  }

  const badgeStyle = {
    backgroundColor: backgroundColor,
    borderRadius: '12px',
    padding: '2px 6px',
    display: 'inline-block',
    textAlign: 'center',
    minWidth: '90px',
    display: 'inline-block',
    cursor: 'pointer',
  };

  const badgeInnerStyle = {
    fontSize: '12px',
    opacity: '1',
    fontStyle: 'normal',
    color: getTextColor(),
    position: 'relative',
  };

  return (
    <span style={badgeStyle} onClick={handleClick}>
      <em style={badgeInnerStyle}>{text}</em>
    </span>
  );
};

export default Badge;
