import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import classes from "./Index.module.css";
import Userdatatable from "./Usertable/Userdatatable";
import SearchBar from "../Common/Searchbar/SearchBar";
import ButtonUser from "../Common/Button/ButtonUser";
import AddNewUserModal from "../UserPage/AddNewUserModal/AddNewUserModal";
import { IMAGES } from "../../assets/images/images";
import { api } from "../../services/auth.serice";
import Loader from "../Common/Loader/Loader";
import axios from "axios";
import Swal from "sweetalert2";
import {
  ACTIVE_INACTIVE,
  DELETE_USER,
  GET_COMPANIES,
  GET_SITES,
  LOGOUT,
  SIGNUP,
  UPDATE_PROFILE,
} from "../../services/URL";
import { useNavigate } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
var FormData = require("form-data");

const UserDashboard = () => {
  const navigate = useNavigate();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isAddNewModal, setIsaddNewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allSites, setSites] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [addUser, setAddUser] = useState({
    id: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    siteId: "",
    password: "",
    type: "user",
    isApprove: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const userSiteId = localStorage.getItem("siteId");
  const getCompanyId = localStorage.getItem("companyId");

  useEffect(() => {
    setAddUser({ ...addUser, siteId: userSiteId });
  }, [userSiteId]);

  const handleClick = () => {
    setIsShowPopup(true);
    setIsEdit(false);
    setAddUser({
      id: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      companyId: getCompanyId,
      siteId: userSiteId,
      password: "",
      type: "user",
      isApprove: true,
    });
  };
  const handleClose = () => {
    setIsShowPopup(false);
    setAddUser({
      id: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      companyId: "",
      siteId: "",
      password: "",
      type: "user",
      isApprove: true,
    });
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setErrorMsg("");
    setEditUser({});
    // setIsaddNewModal(false);
  };

  useLayoutEffect(() => {
    if (searchText.length > 0) {
      handleSearch().then();
    } else {
      getUsers();
    }
  }, [searchText]);

  const handleSearch = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    try {
      const res = await api.searchUser({
        name: searchText,
        siteId: userSiteId,
        headers,
      });
      if (res.status === 200) {
        setUsers([...(res.data || [])]);
      }
      if (res.response.status === 403) {
        try {
          // setIsLoading(true);
          const payload = {
            id: localStorage.getItem("user"),
          };
          const response = await axios.post(LOGOUT, payload, {
            headers,
          });
          localStorage.removeItem("users");
          localStorage.removeItem("user");
          localStorage.removeItem("type");
          // setIsLoading(false);
          navigate("/login");
        } catch (error) {
          console.log(error);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("handleSearch", error);
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    getUsers().then();
    getSites().then();
    getAllCompanies();
  }, []);
  const getUsers = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    const res = await api.getAllUsers({ siteId: userSiteId, headers });
    if (res.status === 200) {
      setUsers(res.data);
      setIsLoading(false);
      localStorage.setItem("users", JSON.stringify(res.data));
    } else if (res.response.status === 403) {
      try {
        // setIsLoading(true);
        const payload = {
          id: localStorage.getItem("user"),
        };
        const response = await axios.post(LOGOUT, payload, {
          headers,
        });
        localStorage.removeItem("users");
        localStorage.removeItem("user");
        localStorage.removeItem("type");
        // setIsLoading(false);
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sort) {
      const sortArr = users?.sort((a, b) => {
        const { first_name: fa } = a;
        const { first_name: fb } = b;
        if (fa?.toLowerCase() < fb?.toLowerCase()) {
          return -1;
        }
        if (fa?.toLowerCase() > fb?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([...sortArr]);
    }
  }, [sort]);

  useEffect(() => {
    if (Object.keys(editUser).length > 0) {
      setEditUser(editUser);
    }
  }, [editUser]);

  const handleApprove = (userId, val) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    axios
      .post(ACTIVE_INACTIVE, { id: userId, value: !val }, { headers })
      .then(async (response) => {
        if (response.data.status === 200) {
          Swal.fire(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (searchText !== "") {
            handleSearch();
          } else {
            getUsers();
          }
        } else {
          Swal.fire(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setIsLoading(false);
      })
      .catch(async (error) => {
        try {
          // setIsLoading(true);
          const payload = {
            id: localStorage.getItem("user"),
          };
          const response = await axios.post(LOGOUT, payload, {
            headers,
          });
          localStorage.removeItem("users");
          localStorage.removeItem("user");
          localStorage.removeItem("type");
          // setIsLoading(false);
          navigate("/login");
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      if (!deletedUser) return;
      const response = await axios.delete(DELETE_USER, {
        headers,
        data: { id: deletedUser },
      });
      if (response.data.status === 200) {
        console.log("up");
        Swal.fire("User has been deleted successfully");
        if (searchText !== "") {
          handleSearch();
        } else {
          getUsers();
        }
        setShowDeleteModal(false);
      } else {
        Swal.fire({
          icon: "error",
          title: response.data.error[0].message,
          text: response.data.error[0].message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setIsLoading(false);
    } catch (error) {
      try {
        const payload = {
          id: localStorage.getItem("user"),
        };
        const response = await axios.post(LOGOUT, payload, {
          headers,
        });
        localStorage.removeItem("users");
        localStorage.removeItem("user");
        localStorage.removeItem("type");
        // setIsLoading(false);
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
      Swal.fire("Failed to delete item");
      setIsLoading(false);
    }
  };

  const handleShowDeleteModal = (id) => {
    setDeletedUser(id);
    setShowDeleteModal(true);
  };
  const handleNameSort = (column, sortDirection) => {
    const sortedUsers = [...users]; // Make a copy of the original 'users' array

    sortedUsers.sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
      const nameB = `${b.first_name} ${b.last_name}`.toUpperCase();

      if (nameA < nameB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      // If the names are equal, sort by email
      const emailA = a.email.toUpperCase();
      const emailB = b.email.toUpperCase();

      if (emailA < emailB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (emailA > emailB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });

    // Update the state with the sorted array
    setUsers(sortedUsers);
  };

  const getSites = async () => {
    axios
      .get(GET_SITES)
      .then((response) => {
        setSites(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllCompanies = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(GET_COMPANIES, { headers });
      setAllCompanies(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const search = useCallback((e) => {
    setSearchText(e);
  }, []);
  const handleShowEditModal = (e) => {
    setIsShowPopup(true);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabled(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      let res;

      if (!isEdit) {
        res = await axios.post(SIGNUP, addUser, { headers });
        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Added Successfully",
            text: "User Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setAddUser({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            companyId: "",
            siteId: "",
            password: "",
            isApproved: false,
          });
          setIsShowPopup(false);
          setIsDisabled(false);
          setIsLoading(false);
          getUsers();
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.error[0],
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        }
      } else {
        if (addUser.siteId === null) addUser.siteId = editUser.siteId;
        delete addUser.email;
        var data = new FormData();
        data.append("id", editUser._id);
        data.append("first_name", addUser.first_name);
        data.append("last_name", addUser.last_name);
        data.append("phone", addUser.phone);
        data.append("siteId", addUser.siteId);
        data.append("companyId", addUser.companyId);
        var config = {
          method: "post",
          url: UPDATE_PROFILE,
          headers: headers,
          data: data,
        };
        axios(config)
          .then(function (res) {
            if (res.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                text: `User Updated Successfully`,
                showConfirmButton: false,
                timer: 1500,
              });
              setIsShowPopup(false);
              setAddUser({
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                companyId: "",
                siteId: "",
                password: "",
                isApproved: false,
              });
              if (searchText !== "") {
                handleSearch();
              } else {
                getUsers();
              }
              setEditUser({});
              setIsLoading(false);
            } else {
              Swal.fire({
                icon: "error",
                text: res.data.error[0],
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log("tets", error);
      try {
        const payload = {
          id: localStorage.getItem("user"),
        };
        const response = await axios.post(LOGOUT, payload, {
          headers,
        });
        localStorage.removeItem("users");
        localStorage.removeItem("user");
        localStorage.removeItem("type");
        localStorage.removeItem("companyId");
        localStorage.removeItem("siteName");
        // setIsLoading(false);
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.userDashboardWrapper}>
      <div className={`${classes.userDashboard} w-100`}>
        <div className={`${classes.leftSide} `}>
          <div>
            <h1>Secure Scan User</h1>
          </div>
          <div className="row py-4">
            <div className="col-sm-5 position-relative">
              <SearchBar
                placeholder="Search User"
                search={(e) => search(e)}
                handleSort={() => setSort(true)}
              />
            </div>
            <div className="col-lg-3 col-sm-5 col-md-4">
              <ButtonUser
                handleClick={handleClick}
                label="Secure scan new user"
                icon={<AiOutlineUserAdd />}
              />
            </div>
          </div>

          <Userdatatable
            users={users}
            handleEdit={(e) => setEditUser(e)}
            editUser={editUser}
            handleApprove={(id, val) => handleApprove(id, val)}
            handleClose={handleClose}
            handleShowDeleteModal={(e) => handleShowDeleteModal(e)}
            handleShowEditModal={(e) => handleShowEditModal(e)}
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
            handleNameSort={handleNameSort}
            sites={allSites}
            isEdit={setIsEdit}
          />
        </div>
      </div>
      {isShowPopup && (
        <AddNewUserModal
          handleClose={handleClose}
          setIsaddNewModal={setIsaddNewModal}
          allSites={allSites}
          users={users}
          allCompanies={allCompanies}
          setAddUser={setAddUser}
          newUser={addUser}
          handleSubmit={handleSubmit}
          firstNameError={firstNameError}
          lastNameError={lastNameError}
          setLastNameError={setLastNameError}
          setFirstNameError={setFirstNameError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          emailError={emailError}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          setIsShowPopup={setIsShowPopup}
          setEmailError={setEmailError}
          editUser={editUser}
          isEdit={isEdit}
        />
      )}
      {showDeleteModal && (
        <div className={classes.deletePopupWrapper}>
          <div className={classes.deletePopupInner}>
            <img src={IMAGES.exclamationIcon} alt="exclamaiton icon" />
            <p>Are you sure you want to delete this user?</p>
            <p>
              This action cannot be undone and you will not be able to recover
              this data.
            </p>
            <div className={classes.btnWrapper}>
              <button
                className={classes.deleteConfirmButton}
                onClick={() => handleDelete()}
              >
                Yes, delete
              </button>
              <button
                className={classes.deleteCancelButton}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default UserDashboard;
