import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./AdminPage.module.css";
import { IMAGES } from "../../assets/images/images";
import { GET_All_VIDEO_EMPLOYEES } from "../../services/URL";
import EmployeeDataTable from "./EmployeeTable/EmployeeDataTable"; // Import the new data table component

const AdminPage = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(GET_All_VIDEO_EMPLOYEES);
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  // Convert seconds to MM:SS format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Format date to a readable format
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className={style.adminPageContainer}>
      <div className={style.logoContainer}>
        <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
      </div>

      <h2 className={style.heading}>Employee Video Watch Status</h2>

      {/* Use EmployeeDataTable to display employees */}
      <EmployeeDataTable
        employees={employees}
        formatTime={formatTime}
        formatDate={formatDate}
      />
    </div>
  );
};

export default AdminPage;
