import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import axios from "axios";
import Loader from "../Common/Loader/Loader";
import BarChart from "../BarCharts/BarChart";
import DoubleLineBarChart from "../EventsChart/DoubleLineBarChart";
import PieChart from "../DountChart/PieChart";
import { DASHBOARD_STATS, LOGOUT } from "../../services/URL";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [users, setUsers] = useState("");
  const [events, setEvents] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState("");
  const [employees, setEmployees] = useState([]);
  const [totalUsers, setTotalUsers] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const siteId = localStorage.getItem("siteId");

  useEffect(() => {
    getStats();
  }, []);
  const companyId = localStorage.getItem("companyId");

  const getStats = () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    axios
      .post(DASHBOARD_STATS, { siteId, companyId }, { headers })
      .then(async (response) => {
        setIsLoading(false);
        if (response && response.status === 200) {
          setEvents(response.data.data.events);
          setTotalEmployees(response.data.data.totalEmployees);
          setUsers(response.data.data.users);
          setEmployees(response.data.data.employees);

          // Check if users and users.active are defined before accessing
          const activeUsers =
            response.data.data.users && response.data.data.users.active;
          const inactiveUsers =
            response.data.data.users && response.data.data.users.inactive;
          const pendingUsers =
            response.data.data.users && response.data.data.users.pending;

          setTotalUsers(
            (activeUsers || 0) + (inactiveUsers || 0) + (pendingUsers || 0)
          );
        } else {
          console.error("Unexpected response status:", response);
        }
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 403) {
          try {
            // setIsLoading(true);
            const payload = {
              id: localStorage.getItem("user"),
            };
            const response = await axios.post(LOGOUT, payload, {
              headers,
            });
            localStorage.removeItem("users");
            localStorage.removeItem("user");
            localStorage.removeItem("type");
            // setIsLoading(false);
            navigate("/login");
          } catch (error) {
            console.log(error);
          }
        }
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <section className={styles.dashboardWrapper}>
      <div className={styles.dashboardInner}>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className={styles.totalCardWrapper}>
              <h4 className={styles.title}>{totalEmployees}</h4>
              <p className={`${styles.description} mb-0`}>Employees</p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={styles.sitesCardWrapper}>
              <h4 className={styles.title}>{totalUsers}</h4>
              <p className={`${styles.description} mb-0`}>Users</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className={`${styles.eventsChartWrapper} pt-2 mb-3`}>
              <h5 className={`${styles.chartHeading} pb-4 pt-4`}>Events</h5>
              <div className={`${styles.eventsChart} `}>
                <DoubleLineBarChart events={events} />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className={`${styles.pieChartWrapper} pt-2 mb-3`}>
              <h5 className={`${styles.chartHeading} pb-4 pt-4`}>Users</h5>
              <div
                className={`${styles.pieChartWrapper} d-flex align-items-center `}
              >
                <div className={`${styles.chart} ps-4`}>
                  {users && <PieChart users={users} />}
                </div>

                <div className={`${styles.customLabel} `}>
                  <span className={`${styles.labelActive} `}>Active</span>
                  <span className={`${styles.labelDeactivate} `}>Deactive</span>
                  <span className={`${styles.labelPending} `}>Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-10">
            <div className={`${styles.titleHeading} `}>
              <h5 className={`${styles.chartHeading} pb-4 pt-4`}>Employees</h5>
            </div>
            <div className={`${styles.employeeChart} `}>
              <BarChart employees={employees} />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </section>
  );
};
export default Dashboard;
