import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Badge from "../Badge/Badge";
import Loader from "../Common/Loader/Loader";
import classes from "./EmployeeTable.module.css";
const EmployeeDataTable = (props) => {
  const {
    users,
    handleNameSort,
    handleShowEventHistory,
    handleGetName,
    handleRowClicked,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(10);

  const getIndex = (index) => {
    if (currentPage === 1) {
      return index + 1;
    } else {
      return index + 1 + (currentPage - 1) * skip;
    }
  };
  const handleRowClick = (item) => {
    handleRowClicked(item);
  };

  const columns = [
    // {
    //   name: <b>Id</b>,
    //   selector: (_, index) => getIndex(index),
    //   sortable: false,
    //   width: "50px",
    // },
    {
      name: <b>Employee Id</b>,
      selector: (item) => item.id,
      sortable: false,
    },
    {
      name: <b>Name</b>,
      selector: (item) => (
        <div className="d-flex align-items-center">
          {/* <div>
            <div
              className={`${classes.itemProfile} rounded-circle overflow-hidden`}
            >
              <img
                src={item.profileImage || avatar}
                alt=""
                className={`${classes.itemImage}`}
              />
            </div>
          </div> */}
          <span
            className={`${classes.itemName}`}
            title={`${item.firstName} ${item.lastName}`}
          >
            {item.firstName} {item.lastName}
          </span>
        </div>
      ),
      minWidth: "200px",
      sortable: true,
    },

    {
      name: <b>Site Id</b>,
      selector: (item) => item.siteId,
      sortable: false,
    },

    {
      name: <b>Card</b>,
      selector: (item) => item.cardIds,
      cell: (item) => (
        
        <Badge
          text={item.cardIds.length > 0 ? "Assigned" : "Unassigned"}
          status={item.cardIds.length > 0 ? "success" : "pending"}
          handleClick={() => handleGetName(item)}
        />
      ),
      sortable: false,
    },

    {
      name: <b>Site Name</b>,
      selector: (item) => (
        <span title={item.siteName} className={`${classes.siteName}`}>
          {item.siteName}
        </span>
      ),
      sortable: false,
      minWidth: "200px",
      width: "200px",
    },

    {
      name: <b>Event History</b>,
      selector: (item) => {
        return (
          <small
            onClick={() => handleShowEventHistory(item)}
            className="cursor-pointer fs-7 btn btn-event-history"
          >
            Event History
          </small>
        );
      },
      width: "130px",
      maxWidth: "130px",
    },
  ];

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePerRowsChange = (s) => {
    setSkip(s);
  };

  return (
    <div className="data-table-wrapper">
      <div id="userDataTable" className={`${classes.userDataTable}`}>
        <DataTable
          // title="User Data"
          columns={columns}
          data={users}
          pagination
          highlightonhover
          onSort={handleNameSort}
          onChangeRowsPerPage={(s) => handlePerRowsChange(s)}
          onChangePage={(pageNumber) => onPageChange(pageNumber)}
          onRowClicked={handleRowClick}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default EmployeeDataTable;
