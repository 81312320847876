import React from "react";
import Select from "react-select";

const SelectBox = ({handleSelectedOption,selectedOption,options}) => {
//   const [selectedOption, setSelectedOption] = useState(null);
  return (
    <div>
      <Select
        defaultValue={selectedOption}
        onChange={handleSelectedOption}
        options={options}
      />
    </div>
  );
};

export default SelectBox;
