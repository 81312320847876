import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation,matchPath } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGES } from "./assets/images/images";
import Dashboard from "./Components//Dashboard/Dashboard";
import Navbar from "./Components/Common/Navbar/Navbar";
import EmployeeDetails from "./Components/EmployeeDetails/EmployeeDetails";
import Login from "./Components/Login/Login";
import {
  CONTACT_US_ROUTE,
  EMPLOYEE_ROUTE,
  JOB_ORIENTATION,
  LOGIN_ROUTE,
  PRIVACY_POLICY_ROUTE,
  ROOT_ROUTE,
  SITES_ROUTE,
  USERS_ROUTE,
  VIDEO_FORM_ROUTE,
  ADMIN_VIDEO_FORM_ROUTE,
} from "./Constant/Index";
import UsersContainer from "./containers/UsersContainer";
import ContactUs from "./pages/contactUs/ContactUs";
import Employees from "./pages/employees/Employees";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sites from "./pages/sites/Sites";
import "./Style.css";
import JobOrientationForm from "./pages/jobOrientationForm/JobOrientationForm";
import VideoForm from "./Components/VideoForm/videoForm";
import AdminPage from "./Components/Video_Employee/AdminPage";

// import useAuth from "./Router/UseAuth";

const App = () => {
  const location = useLocation();
  const [isLoginRoute, setIsLoginRoute] = useState(
    location.pathname === LOGIN_ROUTE
  );

  const isVideoFormRoute = matchPath({ path: VIDEO_FORM_ROUTE, end: false }, location.pathname);
  const isAdminVideoFormRoute = matchPath({ path: ADMIN_VIDEO_FORM_ROUTE, end: false }, location.pathname);

  useEffect(() => {
    setIsLoginRoute(location.pathname === LOGIN_ROUTE);
  }, [location]);
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("type");
  const isEmployeeDetailsRoute = matchPath("/sites/:siteId/company/:companyId/employee/:employeeId", location.pathname);

  console.log('Current Path:', location.pathname); // For debugging
  console.log('Is Employee Route:', isEmployeeDetailsRoute); // For debugging

  return (
    <main className="d-flex w-100">
      {!isLoginRoute && !isVideoFormRoute &&!isAdminVideoFormRoute&&
      location.pathname !== "/page-not-found" &&
      location.pathname !== "/contact-us" &&
      !isEmployeeDetailsRoute ? (
        <section className="pageLeftSide">
          <Navbar
            userRole={userRole}
            logo={IMAGES.safeLogo}
            navLink1="Dashboard"
            navLink2="User"
            navLink3="Sites Admin"
            navlink4="Employees"
            navlink5="Job Orientation"
            // navlink5="Companies"
          />
        </section>
      ) : (
        ""
      )}
      <section
        className={
          isLoginRoute ||isVideoFormRoute||isAdminVideoFormRoute||
          location.pathname === "/page-not-found" ||
          location.pathname === "/contact-us" ||
          isEmployeeDetailsRoute
            ? "pageRightSide full-width"
            : "pageRightSide"
        }
      >
        <Routes>
          <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
          <Route path={CONTACT_US_ROUTE} element={<ContactUs />} />
          <Route path={VIDEO_FORM_ROUTE} element={<VideoForm />} />
                  <Route
                    path={ADMIN_VIDEO_FORM_ROUTE}
                    element={<AdminPage />}
                  />
          <Route path="/sites/:siteId/company/:companyId/employee/:employeeId" element={<EmployeeDetails />} />

          {!token && (
            <>
              <Route path="/*" element={<Navigate to="/login" replace />} />
              <Route path={LOGIN_ROUTE} element={<Login />} />
            </>
          )}

          {token && (
            <>
              {userRole === "admin" ? (
                <>
                  <Route path={SITES_ROUTE} element={<Sites />} />
                  <Route path="/" element={<Navigate to="/sites" replace />} />
                  <Route
                    path="/login"
                    element={<Navigate to="/sites" replace />}
                  />
                  {/* <Route path={COMPANIES_ROUTE} element={<Companies />} /> */}

                  <Route
                    path="/page-not-found"
                    element={<PageNotFound pageName="/sites" />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/page-not-found" replace />}
                  />
                </>
              ) : (
                <>
                  <Route
                    path="/"
                    element={<Navigate to="/dashboard" replace />}
                  />
                  <Route path={ROOT_ROUTE} element={<Dashboard />} />
                  <Route path={USERS_ROUTE} element={<UsersContainer />} />
                  <Route path={EMPLOYEE_ROUTE} element={<Employees />} />
                  {/* <Route path="/employee/:id" element={<EmployeeDetails />} /> */}
                  
                  <Route
                    path={JOB_ORIENTATION}
                    element={<JobOrientationForm />}
                  />
                  {/* <Route
                    path="/"
                    element={<Navigate to="/employee" replace />}
                  /> */}
                  <Route
                    path="/login"
                    element={<Navigate to="/dashboard" replace />}
                  />
                  <Route
                    path="/page-not-found"
                    element={<PageNotFound pageName="/dashboard" />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/page-not-found" replace />}
                  />
                </>
              )}
            </>
          )}
        </Routes>
      </section>
    </main>
  );
};

export default App;
