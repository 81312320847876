import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { Bar, Chart } from "react-chartjs-2";
import { getChartMaxRows, getChartStepSize } from "../../utils";

const BarChart = ({ employees }) => {
  const chartRef = useRef(null);
  const [monthArr, setMonthArr] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState();

  useEffect(() => {
    if (employees && employees.length > 0) {
      const existingMonths = new Set(employees.map((item) => item.month));
      const year = employees[0].month.split("-");

      for (let i = 1; i <= 12; i++) {
        const monthString = `${year[0] || "2023"}-${i}`;
        if (!existingMonths.has(monthString)) {
          employees.push({ month: monthString, count: 0 });
        }
      }

      const sorted = employees.sort((a, b) => {
        const [yearA, monthA] = a.month.split("-");
        const [yearB, monthB] = b.month.split("-");
        return (
          yearA.localeCompare(yearB) || parseInt(monthA) - parseInt(monthB)
        );
      });

      let maxEmp = 0;
      sorted.forEach((emp) => {
        if (emp.count > maxEmp) {
          maxEmp = emp.count;
        }
      });

      setTotalEmployees(maxEmp);
      setMonthArr(sorted);
    }
  }, [employees]);

  useEffect(() => {
    if (monthArr && monthArr.length > 0) {
      const chartInstance = chartRef.current;
      const ctx = chartInstance.getContext("2d");

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
      gradientFill.addColorStop(0, "rgba(100, 180, 49, 1)");
      gradientFill.addColorStop(1, "rgba(235, 247, 226, 1)");

      const chart = new Chart(chartInstance, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Employees",
              data: monthArr.map((m) => m.count),
              backgroundColor: gradientFill,
              borderColor: gradientFill,
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              beginAtZero: true,
              min: 0,
              max: getChartMaxRows(totalEmployees),
              ticks: {
                precision: 0,
                stepSize: getChartStepSize(totalEmployees),
              },
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [monthArr, totalEmployees]);

  return (
    <div style={{ width: "100%", height: "300px", overflow: "hidden" }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default BarChart;
