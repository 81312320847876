// EmployeeDataTable.js
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import style from "../AdminPage.module.css";
// import "./EmployeeDataTable.module./css"; // Add styles if needed

const EmployeeDataTable = ({ employees, formatTime, formatDate }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    setFilteredEmployees(
      employees.filter(
        (employee) =>
          employee.name.toLowerCase().includes(value) ||
          employee.phone.toLowerCase().includes(value) ||
          employee.email.toLowerCase().includes(value)
      )
    );
  };
  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);
  // Define the columns for the DataTable
  const columns = [
    {
      name: <b>#</b>,
      selector: (_, index) => index + 1,
      sortable: false,
      width: "50px",
    },
    {
      name: <b>Name</b>,
      selector: (employee) => employee.name,
      sortable: true,
    },
    {
      name: <b>Phone</b>,
      selector: (employee) => employee.phone,
      sortable: true,
    },
    {
      name: <b>Email</b>,
      selector: (employee) => employee.email,
      sortable: true,
    },
    {
      name: <b>Video Watched</b>,
      selector: (employee) => (employee.watchedVideo ? "✔️" : "❌"),
      center: true, // Center align the icon
    },
    {
      name: <b>Video Watched At</b>,
      selector: (employee) =>
        employee.watchedVideo ? formatDate(employee.videoWatchedAt) : "Incomplete",
      sortable: true,
    },
    {
      name: <b>Last Watched Time</b>,
      selector: (employee) => formatTime(employee.lastWatchedTime),
      sortable: true,
    },
  ];

  return (
    <div className={style.dataTableWrapper}>
       <div className={style.searchContainer}>
        <input
          type="text"
          className={style.searchInput}
          placeholder="Search employees..."
          value={searchText}
          onChange={handleSearch}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredEmployees}
        pagination
        highlightOnHover
      />
    </div>
  );
};

// PropTypes for type checking
EmployeeDataTable.propTypes = {
  employees: PropTypes.array.isRequired,
  formatTime: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
};

export default EmployeeDataTable;
