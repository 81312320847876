import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMAGES } from "../../assets/images/images";
import {
  ASSIGNED_CREDENTIALS,
  FETCH_USER_DETAILS,
  GET_SMARTSHEET_OF_SITES,
  GET_UNASSIGNED_CREDENTIALS,
  UNASSIGNED_CREDENTIALS,
} from "../../services/URL";
import ButtonUser from "../Common/Button/ButtonUser";
import Loader from "../Common/Loader/Loader";
import SelectBox from "../SelectBox/SelectBox";
import styles from "./EmployeeDetails.module.css";
import UserCardForm from "./UserCardForm";
import UserInfo from "./UserInfo";

const EmployeeDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { employeeId,companyId,siteId } = useParams();
  // const { employeeData } = location.state || {};
  // const companyId = localStorage.getItem("companyId");
  // const siteId = localStorage.getItem("siteId");
  const [credentialDetail, setCredentialDetail] = useState([]);
  const [showEmpDetails, setShowEmployeeDetails] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [emplDetails, setEmplDetails] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [smartSheetLink, setSmartSheetLink] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [users, setUsers] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    firstName: "",
    lastName: "",
  });

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/page-not-found");
  //   } else {
  //     setIsAuthenticated(true);
  //   }
  // }, [navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      setSpinLoader(true);
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const userDetailsResponse = await axios.post(
          FETCH_USER_DETAILS,
          { userId: employeeId.toString(), companyId},
          { headers }
        );

        if (userDetailsResponse.status === 200) {
          setEmployeeData(userDetailsResponse.data.data);
          setSpinLoader(false);
          setIsLoading(false);
        }
      } catch (error) {
        setSpinLoader(false);
        setIsLoading(false);
        console.error("Error fetching user details:", error);
      }
    };
    const fetchSmartSheetLink = async () => {
      try {
        const smartsheetLink = await axios.post(
          GET_SMARTSHEET_OF_SITES,
          { siteId, companyId },
        );
        if (smartsheetLink.status === 200) {
          setSmartSheetLink((smartsheetLink.data?.data||{}).sheetLink);
        }
      } catch (error) {
        console.error("Error fetching smartSheetLink:", error);
      }
      
    }
    fetchSmartSheetLink();
    fetchUserDetails();
  }, []);

  // console.log("employeeData", employeeData);
  // const filteredUser = users.filter((user) => user.id !== id);
  // Filter out the current user

  const handleSelectedOption = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (employeeData) {
      setEmplDetails(employeeData);
    }
  }, [employeeData]);

  const [formValues, setFormValues] = useState({
    cardFormat: "Standard 26 Bit",
    externalNumber: "31710",
    internalNumber: "54321",
    facilityCode: "ABC123",
  });
  const labels = {
    cardFormat: "Card Format",
    externalNumber: "External Number",
    internalNumber: "Internal Number",
    facilityCode: "Facility Code",
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(GET_UNASSIGNED_CREDENTIALS, {
        companyId,
      });
      setCredentialDetail(res.data.data);

      if (res.data && Array.isArray(res.data.data)) {
        const dropdownOptions = res.data.data.map((item) => {
          const cardNumber = item.fieldValues.find(
            (field) => field.name === "acs.cards.format.card_number"
          )?.value;

          const facilityCode = item.fieldValues.find(
            (field) => field.name === "acs.cards.format.facility_code"
          )?.value;

          return {
            value: item.id,
            label: item.referenceId,
            cardNumber: cardNumber,
            facilityCode: facilityCode,
          };
        });

        setOptions(dropdownOptions);
      } else {
        console.log("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (employeeData.cardIds?.length > 0) {
      setFormValues({
        cardFormat: "Standard 26 Bit",
        externalNumber: employeeData?.cardIds[0].cardNo,
        internalNumber: employeeData?.cardIds[0].cardNo,
        facilityCode: employeeData?.cardIds[0].facilityCode,
      });
    }
    fetchData();
  }, [employeeData]); // Removed formValues dependency

  const handleClick = async (type) => {
    setIsLoading(true);
    setSpinLoader(true);
    try {
      if (type === "UnAssignCard") {
        const res = await axios.put(UNASSIGNED_CREDENTIALS, {
          companyId,
          userId: employeeData.id.toString(),
          credentialId: employeeData.cardIds[0].id.toString(),
        });

        // navigate(location.pathname);
        setIsLoading(false);
        setSpinLoader(false);
        window.alert("Card UnAssigned successfully!");
        window.location.href = smartSheetLink;
        // window.location.reload();
      } else if (type === "AssignCard") {
        setShowEmployeeDetails(true);
        setIsLoading(false);
        setSpinLoader(false);
      }
    } catch (error) {
      setSpinLoader(false);
      setIsLoading(false);
      console.error("Error in credential operation:", error);
    }
  };

  const handleClickOnCard = async (selectedOption) => {
    setIsLoading(true);

    try {
      const res = await axios.put(ASSIGNED_CREDENTIALS, {
        companyId,
        siteId,
        credentialId: selectedOption.value.toString(), // Use selected credentialId
        userId: employeeData.id.toString(),
        email: employeeData?.emails[0]?.address,
        cardNumber: selectedOption.cardNumber, // Add cardNumber from selected option
        facilityCode: selectedOption.facilityCode, // Add facilityCode from selected option
      });
      setIsLoading(false);
      setShowEmployeeDetails(false);
      // navigate(location.pathname);
      // window.location.reload();
      window.alert("Card assigned successfully!");
      window.location.href = smartSheetLink;
    } catch (error) {
      setIsLoading(false);
      console.error("Error in assigning card:", error);
    }
  };

  return (
    <div className={styles.userDashboardWrapper}>
      <div className={`${styles.userDashboard} w-100`}>
        <div className={`${styles.leftSide} `}>
          <div>
            <h1>Credentials Detail</h1>
          </div>
          <div className="row py-4">
            <div className={styles.width30}>
              <Card>
                <Card.Body>
                  {employeeData?.cardIds?.length > 0 ? (
                    <UserInfo
                      title="Viewing all credentials assigned to:"
                      description={
                        employeeData?.firstName + " " + employeeData?.lastName
                      }
                      subTitle="Standard 26 Bit"
                      externalNumber={employeeData?.cardIds[0].cardNo}
                      handleClick={handleClick}
                      loading={spinLoader}
                      userImg={
                        employeeData.userImage?.userImageUri ||
                        IMAGES.avatarImage
                      }
                    />
                  ) : (
                    <UserInfo
                      title="Viewing all credentials assigned to:"
                      description={
                        employeeData?.firstName + " " + employeeData?.lastName
                      }
                      handleClick={handleClick}
                      userImg={
                        employeeData.userImage?.userImageUri ||
                        IMAGES.avatarImage
                      }
                    />
                  )}

                  <div className="w-100 d-flex justify-content-center mt-3">
                    <ButtonUser
                      label={
                        employeeData?.cardIds?.length > 0
                          ? "UnAssign Card"
                          : "Assign Card"
                      }
                      handleClick={() =>
                        handleClick(
                          employeeData?.cardIds?.length > 0
                            ? "UnAssignCard"
                            : "AssignCard"
                        )
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
            {employeeData?.cardIds?.length > 0 && (
              <div className={styles.width70}>
                <Card className="p-3">
                  <Card.Title>Card Details</Card.Title>
                  <Card.Body className="p-0">
                    <form>
                      {Object.entries(formValues).map(([key, value]) => (
                        <UserCardForm
                          key={key}
                          name={key}
                          value={value}
                          label={labels[key]}
                        />
                      ))}
                    </form>
                  </Card.Body>
                </Card>
              </div>
            )}
            {showEmpDetails && (
              <Modal
                show={showEmpDetails}
                onHide={() => setShowEmployeeDetails(false)}
                size="md"
              >
                <Modal.Header className="modal-user" closeButton />
                <Modal.Body className="p-4 client-dtaa-wrapper">
                  <h1 className="Evant-heading">Assign Card</h1>
                  <div className="">
                    <div className="mt-4">
                      <SelectBox
                        handleSelectedOption={handleSelectedOption}
                        selectedOption={selectedOption}
                        options={options}
                      />
                      <div className="mt-3">
                        <ButtonUser
                          handleClick={() => handleClickOnCard(selectedOption)}
                          label={"Assign Card"}
                          className={"w-100"}
                          disabled={!selectedOption?.value}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default EmployeeDetails;
