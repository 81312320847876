import { apiCall } from "./service";
import {
  EVENTS_HISTORY,
  FETCH_USER_DETAILS,
  GET_ALL_EMPLOYEES,
  GET_COMPANIES,
  GET_SITES_ADMIN,
  GET_USERS,
  SEARCH_ADMINS,
  SEARCH_ALL_EMPLOYEES,
  SEARCH_COMPANY,
  SEARCH_USER,
  SIGNUP,
} from "./URL";
export const api = {
  getAllUsers(params) {
    return apiCall.post({ url: GET_USERS, params });
  },
  getAllSiteAdmins() {
    return apiCall.get({ url: GET_SITES_ADMIN });
  },
  getAllEmployees() {
    return apiCall.get({ url: GET_ALL_EMPLOYEES });
  },
  getAllCompanies() {
    return apiCall.get({ url: GET_COMPANIES });
  },
  addNewUser() {
    return apiCall.post({ url: SIGNUP });
  },
  searchUser(params) {
    return apiCall.post({ url: SEARCH_USER, params });
  },
  searchAdmins(params) {
    return apiCall.post({ url: SEARCH_ADMINS, params });
  },
  searchAllEmployees(params) {
    return apiCall.post({ url: SEARCH_ALL_EMPLOYEES, params });
  },
  searchAllCompamnies(params) {
    return apiCall.post({ url: SEARCH_COMPANY, params });
  },
  eventsHistory(params) {
    return apiCall.post({ url: EVENTS_HISTORY, params });
  },
  fetchUserDetails(params) {
    return apiCall.post({ url: FETCH_USER_DETAILS, params });
  },
};
